@import '../../../assets/styles/variables.scss';

.tk_table {
  &-container {
    overflow-x: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    background-color: $primary-light;
    border-bottom: 1px solid $default-border-color;
    tr {
      th {
        color: $text-head;
        font-weight: 600;
        padding: 12px 16px;
        text-transform: capitalize;
        transition: $default-transition;
        &[data-sortable='true'] {
          cursor: pointer;
          &:hover {
            background-color: #eee;
          }
        }
        &[data-sort-direction='asc'] {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background-image: url('../../../assets/icons/chevron-up.svg');
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            right: 5px;
            top: calc(50% - 8px);
          }
        }
        &[data-sort-direction='desc'] {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background-image: url('../../../assets/icons/chevron-down.svg');
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            right: 5px;
            top: calc(50% - 8px);
          }
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: $primary-light;
      }
      td {
        padding: 12px 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }
    }
  }
}