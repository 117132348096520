@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');

* {
	font-family: 'poppins';
	color: $text;
	font-size: 16px;
}

body {     
	background: url(../images/bg.png) #ececec no-repeat top center;
    background-position-y: 200px;
}

h3, h4, h5  { 
	& > i, strong, blockquote {
		font-size: unset !important;
	}
}



.tk_display-xxl {
	@include headers;
	font-size: 45px;
}

.tk_display-xl {
	font-weight: 600;
	color: $text-head;
	font-size: 25px;
	letter-spacing: 1.54px;
}

.tk_display-l {
	@include headers;
	font-size: 24px;
	letter-spacing: 0.79px;
}




.tk_display-m {
	@include headers;
	font-size: 18px;
	letter-spacing: 0.59px;
}

.tk_card-header-lg {
	@include headers;
	font-size: 20px;
	letter-spacing: 0.66px;
}

.tk_card-header-sm {
	font-weight: 600;
  color: $text-head;
	font-size: 14px;
	letter-spacing: 0.46px;
}


.tk-tab-res {
	margin-top: -30px;
}

.tk-acc-res {
	margin-top: -30px;
}

@media (max-width: 768px) {
	.tk-tab-res {
		display:none
	}
}

@media (min-width: 769px) {
	.tk-acc-res {
		display:none
	}
}