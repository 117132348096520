@import '../../assets/styles/variables.scss';

.tk_tab {
  &-container {
    display: flex;
    position: relative;
  }
  &-menu {
    flex: 0 1 358px;
    > .nav-item {
      background-color: #fff;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 2.56;
      box-shadow: $accordion-box-shadow;
      text-transform: capitalize;
      > .nav-link {
        padding: 20px 23px 20px 20px;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: $default-transition;
        &.active {
          background-color: $primary-light;
          color: $primary;
          border-left: 6px solid $primary;
          > .tk_tab-icon {
            background-color: $primary;
            > .tk_icon {
              background-color: #fff !important;
            }
          }
        }
        &:hover {
          color: $primary;
        }
        &::selection {
          color: #fff;
          background-color: #fff;
        }
      }
    }
  }
  &-icon {
    width: 33px;
    height: 33px;
    border: 1px solid $text;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    transition: $default-transition;
    > .tk_icon {
      transform: rotate(180deg);
    }
  }
  &-content {
    background-color: $primary-light;
    //position: absolute;
    left: 358px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    padding: 20px;
    line-height: 2.5;
    letter-spacing: 0.04px;
    font-weight: 500;
    > .tab-pane {
      color: $primary;
    }
  }
}