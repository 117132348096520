@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/typography.scss";

.tk_workplace_management {
  &-buttons {
    > button {
      width: 170px;
    }
  }
}

.tk_workplace_management-modal {
  & .modal-body {
    height: 80vh;
    overflow-y: auto;
  }
}
