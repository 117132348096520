@import '../../../../assets/styles/variables.scss';

.tk_hp {
  &-image-slider {
    // height is =~ 475px
    // padding-top: 47px;
    width: 100%;
    position: absolute;
    left: 0;
  }
  &-news {
    margin-top: 70px;
  }
  &-last-added {
    margin-top: 100px;
  }
  &-status {
    position: relative;   
    @media (max-width: 1420px) {
    }
    @media (max-width: 1199px) {
      width: calc(100% + 40px);
      > .tk_cards-container {
        padding: 0 20px;
      }
    }
  }

  &-campaigns {
    margin-top: 70px;
  }
}

.tk_hp-status-div{
  background: #398e9842 no-repeat;
}
.tk_hp-campaigns-div{
}
.tk_hp-news-div{  
}

