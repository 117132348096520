@import '../../../../../assets/styles/variables.scss';

.tk_load-money-btn {
  margin-right: 0.5rem;
  > button {
    margin-left: auto;
    display: flex;
    align-items: center;
    > .tk_icon {
      margin-left: 10px;
    }
  }
}

.tk_load-money-modal {
  .modal-body {
    overflow: visible !important;
  }
}