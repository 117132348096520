@import "../../assets/styles/variables.scss";

$icons: (
  "instagram",
  "facebook",
  "twitter",
  "credit-card",
  "group",
  "group-2",
  "restaurant",
  "scissors",
  "star",
  "store",
  "verified",
  "menu-burger",
  "user-2",
  "newspaper",
  "arrow",
  "plus",
  "delete",
  "edit",
  "location",
  "my-location",
  "bar-chart",
  "print",
  "file-excel",
  "arrow_down",
  "download",
  "payment",
  "saving"
);

.tk_icon {
  display: inline-block;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center center;
  -webkit-mask-position: center center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  cursor: pointer;
  transition: $default-transition;
  @each $name in $icons {
    &-#{$name} {
      -webkit-mask-image: url("../../assets/icons/#{$name}.svg");
      mask-image: url("../../assets/icons/#{$name}.svg");
    }
  }
  &.tk_delete:hover {
    background-color: $bg-red !important;
  }
  &.tk_edit:hover {
    background-color: $bg-green !important;
  }
  &.tk_print {
    margin-left: 3px !important;
    &:hover {
      background-color: $bg-dark-blue !important;
    }
  }
}
