@import '../../../../assets/styles/variables.scss';

.tk_file-input {
  .custom-file-input {
    z-index: 1;
  }
  > .custom-file-label {
    > span {
      display: inline-block;
      width: calc(100% - 54px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.tk_file-input-error {
    .custom-file-input:focus ~ .custom-file-label {
      box-shadow: $input-error-box-shadow !important;
    }
    > .custom-file-label {
      border-color: $input-error-border-color !important;
    }
  }
}