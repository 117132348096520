@import "../../../../../assets/styles/variables.scss";

.tk_details-campaign-card .tk_card {
  margin-top: 15px;
  padding: 0;
  background-color: unset!important;
}

.tk_details-campaign-card {
  margin-top: 60px;
}

.tk_join-campaign{
  margin-top: 55px 0;
  &-text {
    @include headers;
    
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }

  &-button {
    display: flex;
    justify-content: center;
  }
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}