@import "../../../../assets/styles/variables.scss";

.tk_transactions {
  min-height: 30px;
  white-space: nowrap;

  &-transaction {
    cursor: pointer;
    
    &-title {
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-at {
    }
  }
}