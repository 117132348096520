@import '../../assets/styles/variables.scss';

.tk_accordion {
  &-card {
    box-shadow: $accordion-box-shadow;
    margin-bottom: 10px;
    background: #fff;
  }
  &-toggle {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $accordion-border-color;
    transition: $default-transition;
    transition-delay: 5ms;
    cursor: pointer;
    &:hover {
      .tk_accordion-icon {
        background-color: $primary;
        > .tk_icon {
          background-color: #fff !important;
        }
      }
    }
  }
  &-question {
    width: calc(100% - 48px);
    color: $primary;
    font-size: 17px;
    letter-spacing: 0.04px;
    line-height: 2.41;
    font-weight: bold;
  }
  &-icon {
    width: 33px;
    height: 33px;
    border: 1px solid $text;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    transition: $default-transition;
    > .tk_icon {
      transform: rotate(-90deg);
    }
  }
  &-body {
    padding: 35px 20px 20px;
    background-color: $primary-light;
    border-left: 6px solid $primary;
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 500;
  }
  &-active {
    background-color: $primary-light;
    border-width: 0;
    border-left: 6px solid $primary;
    .tk_accordion-icon {
      background-color: $primary;
      > .tk_icon {
        background-color: #fff !important;
        transform: rotate(90deg);
      }
    }
  }
}