@import '../../assets/styles/variables.scss';

.tk_button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-size: 13px;
  color: $text-white;
  transition: $default-transition;
  display: flex;
  align-items: center;
  justify-content: center;
  > .tk_loader {
    margin-left: 5px;
  }
  &:hover {
    color: $text-white;
    opacity: 0.9;
  }
  &:focus {
    color: $text-white;
  }
  &-share {
    width: 195px;
    padding: 11px 41px !important;
  }
  &-join-campaign {
    border-radius: 20px;
    width: 220px;
    padding: 11px 20px !important;
  }
  &.btn-primary:hover {
    background-color: $primary;
  }
  &.btn-secondary:hover {
    background-color: $secondary;
  }
  &.btn-light, &.btn-light:hover {
    color: $text-head;
  }
  &.btn-danger {
    background-color: $bg-red;
  }
  &.btn-new {
    background-color: $newsecondarycolor;
  }
}
