@import "../../assets/styles/variables.scss";

.tk_banner-slider {
  background: url(../../assets/images/slider.png) no-repeat top center;
  position: relative;
  height: 583px;
  @media (max-width: 900px) {
    height: 480px !important;
  }
  @media (max-width: 720px) {
    height: 390px !important;
  }
  @media (max-width: 525px) {
    height: 330px !important;
  }
  z-index: 100;
  padding-top: 80px;
  &-img {
    @media (max-width: 720px) {
      height: 240px !important;
    }
    @media (max-width: 900px) {
      height: 330px;
    }
    @media (max-width: 525px) {
      height: 180px !important;
    }

    margin-top: 80px;
    height: 423px;
    border-radius: 4px;
  }

  .bg-img {
  }

  .slick-prev {
    margin-top: 35px;
    left: 5px;
    z-index: 1;
    height: 50px;
    width: 50px;
  }
  .slick-prev:before {
    font-size: 50px;
  }
  .slick-next {
    margin-top: 35px;
    right: 5px;
    height: 50px;
    width: 50px;
    z-index: 1;
  }
  .slick-next:before {
    font-size: 50px;
  }

  &-caption {
    @include headers;
    width: 100%;
    padding: 10px 10px 15px 30px;

    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;

    &-text {
      font-size: 30px;
      color: $text-white;
      border-left: 4px solid $secondary;
      padding: 0px 0px 0px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .tk_banner-slider {
    padding-top: 70px;
    height: 573px;
  }
}

@media (max-width: 525px) {
  .tk_hp-status-div .tk_status-amount {
    font-size: 27px !important;
    span {
      font-size: 27px !important;
    }
  }
}
