@import '../../../../assets/styles/variables.scss';

.tk_content-form {
  .ck-editor__main .ck-content {
    min-height: 150px;
    max-height: 450px;
    overflow: auto;
    transition: $default-transition;
    > *, ol > li, ul > li {
      color: $input-text;
    }
  }
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border-color: $custom-input-border-color !important;
    box-shadow: $input-box-shadow !important;
  }
  .tk_ck-editor-error {
    .ck.ck-editor__editable {
      border-color: $input-error-border-color !important;
    }
    .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
      border-color: $input-error-border-color !important;
      box-shadow: $input-error-box-shadow !important;
    }
  }
}