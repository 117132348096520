@import '../../../assets/styles/variables.scss';

.tk_slider {
  > .slick-list {
    > .slick-track {
      transition-delay: 0.1s;
    }
  }
  > .slick-dots {
    z-index: 200;
    > li {
      width: 7px;
      height: 7px;
      margin: 0 4px;
      > div {
        width: 7px;
        height: 7px;
        background-color: $bg-indicator;
        border-radius: 50%;
        transition: $default-transition ;
        &:hover {
          background-color: $text-head;
        }
      }
      &.slick-active > div {
        background-color: $text-head;
      }
    }
  }
  &-three {
    .slick-slide {
      margin-right: 27px;
    }
    &-shadows {
      .slick-slide {
        margin-right: 22px;
      }
    }
  }
  &-four {
    .slick-slide {
      margin-right: 22px;
    }
    &-shadows {
      .slick-slide {
        margin-right: 19px;
      }
    }
  }
  &-five {
    .slick-slide {
      margin-right: 18px;
    }
    &-shadows {
      .slick-slide {
        margin-right: 16px;
      }
    }
  }
  &-padding {
    > .slick-list {
      padding: 20px 0;
    }
    &-shadows {
      > .slick-list {
        padding: 20px 5px;
      }
    }
  }
}

.slick-prev:before {
  color: #f5f5f5!important
}

.slick-next:before {
  color: #f5f5f5!important
}

.tk_banner-slider .slick-slider .slick-dots {
  bottom: 13px;
}