@import '../../../../assets/styles/variables.scss';

.tk_coordinates-picker {
  &-my-location {
    &-disabled {
      background-color: $bg-grey !important;
    }
  }

  &-map{
    position: relative;
    width: 100%;
    height: 350px;
    margin: 0 auto;
    // margin-top: 50px;
    background-color: $primary-light;
    @media (max-width: 700px) {
      width: 80%;
    }
  }
}

#tk_current-location-tooltip {
  .tooltip-inner {
    max-width: 300px !important;
    font-size: 0.9em !important;
  }
}