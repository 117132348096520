@import '../../assets/styles/variables.scss';

.tk_checkbox {
  display: block;
  padding-left: 30px;
  margin: 0;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $text-black;
  font-size: 14px;
  text-transform: capitalize;
  > input {
    position: absolute;
    opacity: 0;
    &:checked ~ .tk_checkbox-checkmark {
      background-color: $primary;
    }
    &:checked ~ .tk_checkbox-checkmark:after {
      display: block;
    }
  }
  &-checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc((30px - 16px) / 2);
    height: 16px;
    width: 16px;
    border: 1px solid $primary;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $default-transition;
    &:after {
      content: "";
      position: absolute;
      display: none;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

