.tk_user-circle-dropdown {
  &-menu {
    left: -120px;
    width: 150px;
  }
}

.tk_user-circle-button {
  color: white;
  width: fit-content;
  white-space: nowrap;
  border: 0px;
  background-color: transparent;

}

.tk_user_circle {
}