@import "../../assets/styles/variables.scss";

.tk_dropdown {
  > button {
    background-color: $primary;
    width: 230px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 18px 11px 28px;
    border: none;
    border-radius: 4px;
    letter-spacing: 0.8px;
    font-weight: 600;
    transition: $default-transition;
    &:focus {
      border-color: $custom-input-border-color;
      box-shadow: $input-box-shadow;
    }
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      color: $text-white;
      margin-right: 10px;
    }
    .badge {
      background-color: $primary-light;
    }
  }
  > .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    box-shadow: $default-box-shadow;
  }
  &-item {
    padding: 5px 10px;
  }
  &-light {
    > button {
      background-color: #fff;
      border: 1px solid $input-border-color;
      .badge {
        background-color: $primary;
        color: #fff;
      }
      > span {
        color: $input-text;
      }
      > div > i {
        background-color: $input-text !important;
      }
    }
  }
  &-invalid {
    > button {
      border-color: $input-error-border-color;
      &:focus {
        border-color: $input-error-border-color;
        box-shadow: $input-error-box-shadow;
      }
    }
  }
}
