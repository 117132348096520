@import '../../../assets/styles/variables.scss';

.tk_balance-modal {
  &-name {
    font-size: 1.3rem;

  }
  
  &-balance {
    font-size: 1.3rem;
    font-weight: 300;
  }

  &-tl {
    font-size: 1rem;
    font-weight: 300;
    margin-left: 0.25rem;
  }

  &-load-money {
    &-input {
      width: 100%;
    }

    &-button {
      margin: 0px 10px;
      width: "fit-content";
      white-space: nowrap;
    }
  }
}