@import '../../../../assets/styles/variables.scss';

.tk_pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  > li {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 4px;
    > button {
      padding: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      color: $text !important;
      &:focus {
        box-shadow: none;
        background-color: transparent;
      }
      &.tk_pagination-chev-right {
        transform: rotate(180deg);
      }
      &.tk_pagination-active {
        background-color: $secondary;
        color: $text-white !important;
      }
    }
  }
}