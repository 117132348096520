.tk_image-slider {
  position: relative;
  z-index: 100;
  padding-bottom: 45px;
  &-img {
    height: 373px;
    width: 100%;
    border-radius: 4px;
  }

  .slick-prev{
    left: 5px;
    z-index: 1;
    height: 50px;
    width: 50px;
  }
  .slick-prev:before {
      font-size: 50px;
    }
  .slick-next{
    right: 5px;
    height: 50px;
    width: 50px;
    z-index: 1;
  }
  .slick-next:before {
    font-size: 50px;
  }
}