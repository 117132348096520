.tk_details-header-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .tk_display-xl {
    margin-right: 10px;
    flex: 0 1 calc(100% - 105px);
    @media (max-width: 576px) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}