.tk_status-container {
  padding-top: 32px;
  padding-bottom: 20px;
}


@media (min-width: 576px) {
  .tk_status-card:first-child{
    text-align: left!important;
  }
  
  .tk_status-card:last-child{
    text-align: right!important;
  }
}
@media (max-width: 576px) {
		.tk_status-card:first-child{
      text-align: center!important;
    }
    
    .tk_status-card:nth-child(2){
      text-align: start!important;
    }

    .tk_status-card:last-child{
      text-align: center!important;
    }
}

@media (max-width: 500px) {
  .tk_status-card:nth-child(2){
    margin-right: 1px;
  }

}