.tk_page-header {
  margin: 80px 0 0px;
 
}

.centered {
  top: 50%;
  left: 50%;
}

.tk_display-text{
  position: absolute;
  bottom: 0;
  top: 50%;
  color: #fff;
}

.header-image {
  z-index: -1;
  position: relative;
  background-image: url('../../assets/images/display-bg.png');
  width: auto;
  height: 400px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: repeat-x;
}