@import "../../assets/styles/variables.scss";

.tk_preregistration-container {
  padding: 30px 0px 25px 0px;
  &-button {
    width: 229px;
  }
  &-modal {
    z-index: 2100;
  }
}