@import '../../../assets/styles/variables.scss';

.tk_add-new-btn {
  > button {
    margin-left: auto;
    display: flex;
    white-space: nowrap;
    align-items: center;
    > .tk_icon {
      margin-left: 10px;
    }
  }
}