.tk_slider-container {
  padding-bottom: 20px;
  
  .slick-prev{
    left: 5px;
    z-index: 1;
    height: 50px;
    width: 50px;
  }
  .slick-prev:before {
      font-size: 50px;
    }
  .slick-next{
    right: 5px;
    height: 50px;
    width: 50px;
    z-index: 1;
  }
  .slick-next:before {
    font-size: 50px;
  }
}