.tk_details-image {
  position: relative;
//  height: 621px;
  border-radius: 4px;
  margin-top: 170px;
}

.tk_details-image img{
  width: 100%;
 // height: 621px;
  object-fit: cover;
}


.tk_details-image-batch{
  font-size: 40px;
      position: absolute;
      background: #bf423c;
      top:1rem;
      right:1.5rem;
      width:100px;
      height: 100px;
      text-align: center;
      line-height: 6rem;
      border-radius: 20%;
      color:white;
      z-index: 1000;
}