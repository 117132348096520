@import '../../assets/styles/variables.scss';



.tk_card {
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 0;
  background-color: #fff;
  transition: $default-transition;
  box-shadow: $default-box-shadow;
  &:hover {
    top: -15px;
  }
  &-header {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-desc {
    font-size: 12px;
    letter-spacing: -0.3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-stars-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }
  &-rate, &-icon {
    > .tk_card-header {
      margin-top: 10px;
    }
  }
  &-icon {
    &-container {
      display: flex;
      margin-top: 10px;
    }
  }
  &-sm {
    width: 185px;
    height: 247px;
    > .tk_card-img {
      height: 120px;
    
    }
    > .tk_card-member {
      font-size: 12px;

      padding: 8px 10px;
    
    }
    > .tk_card-header {
      margin-top: 18px;

      padding: 8px 10px;
    }
    > .tk_card-desc {

      padding: 8px 10px;
      max-height: 54px;
    }
    > .tk_card-badge {
      font-size: 10px;
      position: absolute;
      height: 30px;
      background: #bf423c;
      top:1rem;
      right:1.0rem;
      width:30px;
      text-align: center;
      line-height: 1.8rem;;
      border-radius: 20%;
      color:white;
    }
  }
  &-md {
    width: 260px;
    min-height: 225px;
    margin: 8px 10px !important;
    /*padding: 8px 10px;*/
    > .tk_card-img {
      height: 146px;
      padding: 8px 10px;
    }
    > .tk_card-header {
      padding: 8px 10px;
      margin-top: 18px;
    }
    > .tk_card-desc {
      padding: 8px 10px;
      max-height: 54px;
    }
    > .tk_card-badge {
      font-size: 10px;
      position: absolute;
      height: 30px;
      background: #bf423c;
      top:1rem;
      right:1.5rem;
      width:30px;
      text-align: center;
      line-height: 1.8rem;;
      border-radius: 20%;
      color:white;
    }
  }
  &-lg {
    width: 315px;
    height: 350px;
    margin: 20px !important;
    > .tk_card-img {
      height: 165px;
      padding: 20px;
    }
    > .tk_card-header {
      padding: 20px;
      margin-top: 10px;
    }
    > .tk_card-desc {
      padding: 20px;
      max-height: 72px;
    }
    > .tk_card-badge {
      font-size: 20px;
      position: absolute;
      background: #bf423c;
      top:1rem;
      right:1.5rem;
      width:50px;
      height: 40px;
      text-align: center;
      line-height: 2rem;;
      border-radius: 20%;
      color:white;
    }
  }
  &-auto {
    width: 100%;
    padding: 33px;
    &:hover {
      top: 0;
    }
    > .tk_card-img {
      height: 621px;
      margin-top: 100px;
    }
    > .tk_card-icon-container {
      margin-top: 15px;
      > .tk_icon {
        width: 33px !important;
        height: 33px !important;
      }
    }
    > .tk_card-header {
      margin-top: 20px;
      padding: 8px 10px;
    }
    > .tk_card-desc {
      padding: 8px 10px;
    
      font-size: 16px;
      letter-spacing: -0.4px;
      margin-top: 15px;
    }
    > .tk_card-badge {
      font-size: 40px;
      position: absolute;
      background: #bf423c;
      top:1rem;
      right:1.5rem;
      width:100px;
      height: 100px;
      text-align: center;
      line-height: 6rem;;
      border-radius: 20%;
      color:white;
      margin-top: 100px;
    }
  }
  &-bg {
    height: auto;
    > .tk_card-desc {
      color: $text-white;
    }
    &-white {
      background-color: #fff;
      .tk_card-desc {
        color: $text;
      }
    }
    &-yellow {
      background-color: $bg-yellow;
      > .tk_card-header {
        color: $text-yellow;
      }
    }
    &-red {
      background-color: $bg-red;
      > .tk_card-header {
        color: $text-red;
      }
    }
    &-green {
      background-color: $bg-green;
      > .tk_card-header {
        color: $text-green;
      }
    }
    &-dark-blue {
      background-color: $bg-dark-blue;
      > .tk_card-header {
        color: $text-blue;
      }
    }
    &-light-blue {
      background-color: $bg-light-blue;
      > .tk_card-header {
        color: $text-blue;
      }
    }
    &-grey {
      background-color: $bg-grey;
      > .tk_card-img {
        filter: grayscale(100%);
      }
      > .tk_card-header {
        color: $text-grey;
      }
    }
  }
  &-border {
  }
  &-shadow {
    box-shadow: $default-box-shadow;
  }
  &-badge{
    margin-bottom: -100px;
    position: absolute;
    background: #bf423c;
    height:10%;
    top:1rem;
    right:1.5rem;
    width:10%;
    text-align: center;
    line-height: 2rem;;
    font-size: 50%;
    border-radius: 50%;
    color:white
}

}

