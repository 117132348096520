@import '../../assets/styles/variables.scss';

.tk_details-content {
  background-color: $primary-light;
  padding: 20px;
  margin-top: 30px;
  &-desc {
    line-height: 1.88;
    letter-spacing: -0.3;
    margin-top: 25px;
  }
}