.tk_status {
  &-info {
    display: inline-block;
    text-align: left;
  }
  &-title {
    padding-bottom: 5px;
    border-bottom: 3px solid #087f93;
    color: black;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 0.46px;
    display: flex;
    > i {
      margin-right: 10px;
      color: black !important;
    }
  }
  &-amount {
    font-size: 49px;
    text-align: center;
  }
}

.tk_status-amount span {
  color: black;
  font-size: 45px;
}
