@import '../../../assets/styles/variables.scss';

.tk_modal {
  .modal-content {
    max-height: 90vh;
  }
  .modal-title {
    color: $text-head;
    text-transform: capitalize;
    font-weight: 600;
  }
  .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}