@import "../../assets/styles/variables.scss";

.tk_footer {
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;  
    @media (max-width: 1199px){
      width: auto;
    }
  }
  &-mobillinks{
    margin-left: -15px;
    margin-top: 25px;
    display: flex;
    img {
    margin-left: 15px;
  }}
  &-contact{
    margin-top: 15px;
    margin-left: 60px;
    @media (max-width: 1024px){
      margin-left: 0px;
    }
    a button {
      width: 190px;
      border-radius: 20px;
    }
  }
  &-contacttext{
    width: 190px;
    font-size: 30px;
    text-align: center;
    color: white;
    margin-left: 60px;
    text-transform: uppercase;
    margin-top: 12px;
    @media (max-width: 1024px){
      margin-top: 15px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  
  &-links-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    img {
      margin-top: 9px;
      width: 190px;
    }
    @media (max-width: 1024px){
      flex-direction: column;
      margin-right: -10px;
      img {
        margin-bottom: 15px;
      }
    }
  }
  &-links {
    width: calc(100% - 141px - 20px);
    display: flex;
    > a {
      text-transform: capitalize;
      text-decoration: none;
      color: $text-white;
      font-size: 12px;
      transition: $default-transition;
      &:hover {
        color: $secondary;
      }
    }
    @media (max-width: 1024px){
      justify-content: center;
      flex-wrap: wrap;
      width: auto;
      > a {
        margin: 15px;
      }
    }
  }
  &-sm-icons {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 141px;
    display: flex;
    justify-content: space-between;
    > a {
      width: 40px;
      height: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 1024px){
      width: auto;
      justify-content: center;
      > a {
        margin: 5px;
      }
    }
  }
  &-text {
    margin-top: 30px;
    color: $text-purple;
    font-size: 21px;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 1024px){
      margin-top: 35px;
    }
  }
  @media (max-width: 1024px){
    height: auto;
  }
}

.tk_footer-sm-icons a:nth-child(1):hover{
  background-color: #3b5998;
}

.tk_footer-sm-icons a:nth-child(2):hover{
  background-color: #1DA1F2;
}

.tk_footer-sm-icons a:nth-child(3):hover{
  background-color: #E1306C;
}