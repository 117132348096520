@import '../../assets/styles/variables.scss';

.tk_details-address {
  // margin-top: 30px;
  &-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      .tk_button-container {
        margin-left: 0;
        margin-top: 10px;
        align-self: center;
      }
    }
    .tk_display-l {
      margin-bottom: 16px;
    }
    .tk_button-container {
      margin-left: 10px;
    }
  }
  &-map {
    position: relative;
    width: 636px;
    height: 353px;
    margin: 0 auto;
    margin-top: 50px;
    background-color: $primary-light;
    @media (max-width: 700px) {
      width: auto;
    }
  }
}