.tk_cards-container {
  @media (max-width: 1199px) {
    width: auto;
  }
  > .tk_section-header {
    margin-bottom: 35px;
  }
  > .infinite-scroll-component__outerdiv {
    > div.infinite-scroll-component {
      overflow-y: hidden !important;
      column-gap: 33px;
      row-gap: 12px;
    }
    > .row {
      padding-top: 20px;
      > .tk_card {
        margin-left: 21px;
        margin-bottom: 20px;
      }
    }
  }
}
