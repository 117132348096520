.tk_campaigns {
  &-continued {
    margin-top: 50px;
    // &-slider {
    //   margin-top: 30px;
    // }
  }
  &-future {
    margin-top: 50px;
  }
}

.tk_campaigns-continued-slider .tk_card-member{
  font-size: 12px;
  height: 35px;

}