@import '../../assets/styles/variables.scss';

.tk_filter {
  &-container {
    padding: 14px 0px 14px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-light;
    border-radius: 0 0 4px 4px;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: unset;
    }
    @media (max-width: 576px) {
      padding: 23px;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    span {
      color: $text-black;
      font-size: 13px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin-left: 15px;
    }
    @media (max-width: 992px) {
      margin-bottom: 15px;
    }
  }
  &-select-group {
    display: flex;
    > div {
      margin-left: 15px;
    }
    @media (max-width: 992px) {
      > div {
        margin-left: 0;
        margin-right: 15px;
      }
    }
    @media (max-width: 768px) {
      justify-content: space-between;
      > div {
        margin: 0;
        width: 49%;
        > button {
          width: 100%;
        }
      }
    }
    @media (max-width: 576px) {
      > div {
        > button {
          padding: 11px 12px 11px 16px;
        }
      }
    }
  }
}

.tk_filter-search{
  margin-top: 5px;
  position: relative;
  button {
    position: absolute;
    top: 0;
    right: 5px;
    background: 0;
    border: 0;
    line-height: 28px;
  }
}