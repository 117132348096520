@import "../../assets/styles/variables.scss";

.tk_select {
  > button {
    width: 100%;
    background-color: #fff;
    border: 1px solid $input-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    transition: $default-transition;
    &:focus {
      border-color: $custom-input-border-color;
      box-shadow: $input-box-shadow;
    }
    > span {
      color: $input-text;
      margin-right: 10px;
      text-transform: capitalize;
    }
    > div > i {
      background-color: $input-text !important;
    }
  }
  > .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    box-shadow: $default-box-shadow;
    > a {
      font-size: 14px;
      color: $input-text;
      text-transform: capitalize;
    }
  }
  &-item-active,
  &-item-active:hover,
  .dropdown-item:focus {
    text-decoration: none;
    background-color: $primary-light;
  }
  &-invalid {
    > button {
      border-color: $input-error-border-color;
      &:focus {
        border-color: $input-error-border-color;
        box-shadow: $input-error-box-shadow;
      }
    }
  }
}
