.tk_search-text {
    // max-width: 00px;
    margin-top: 30px;
    &-input {
      max-width: 300px;
    }
    &-vkn{
      width: 150px;
    }
    &-entitle{
      width: 450px;
    }
  }