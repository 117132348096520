
@import "../../../assets/styles/variables.scss";

.tk_user-data {
    background-color: #fff;
    box-shadow: $default-box-shadow;
    padding: 20px;
}

.tk_user-row {
    margin-bottom: 20px;
}

.user-col {
    margin-bottom: 20px;
}