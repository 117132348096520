@import "./variables.scss";
@import "./typography.scss";
@import "~bootstrap/scss/bootstrap";

body,
html {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $primary-light;
}

::-webkit-scrollbar-thumb {
  background: $text;
}

::-webkit-scrollbar-thumb:hover {
  background: #666c72;
}

.cursor-pointer {
  cursor: pointer;
}

.text-dark {
  color: $text-head;
}

.bg-img {
  background: no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-label,
.form-check-label {
  text-transform: capitalize;
}

.swal2-container {
  z-index: 30000 !important;
}

.modal {
  z-index: 2100 !important;
}

.bg-primary-light{
  background: #f6f6fa !important;
}

@media (max-width: 767px) {
  .infinite-scroll-component {
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 1200px){
  .container{
    max-width: 1225px;
  }  
}