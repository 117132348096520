@import "../../../../assets/styles/variables.scss";

.topbar {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.sidebar {
  position: fixed;
  z-index: 100;
  width: 0px;
  height: calc(100vh - 56px);
  transition: width 0.5s;
  overflow-y: auto;
}
.sidebar-open {
  width: 275px;
}
.sidebar-close {
  width: 0px;
  padding: 0px !important;
}
.sidebar-content {
  display: none;
}
.sidebar-content-open {
  display: block;
}
.sidebar-content-close {
  display: none;
}
.content {
  margin-left: 0px;
  padding-bottom: 15px;
  transition: all 0.5s;
}
.content-open {
  margin-left: 275px;
  width: calc(100% - 275px);
}
.content-close {
  margin-left: 0px;
}
.tk_container-bg {
  background-color: $primary-light;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -100;
}
.tk_msb {
  .nav-item {
    > a {
      display: inline-block;
      width: 100%;
      padding: 10px 20px;
      transition: $default-transition;
      &:hover {
        text-decoration: none;
        color: $secondary;
      }
    }
  }
  &-active {
    border-left: 3px solid $secondary;
    color: $secondary;
  }
}

@media screen and (max-width: 576px) {
  .content-open {
    width: 100%;
    margin-left: 0px;
  }
  .sidebar {
    -webkit-box-shadow: 13px 0px 10px -7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 13px 0px 10px -7px rgba(0, 0, 0, 0.5);
    box-shadow: 13px 0px 10px -7px rgba(0, 0, 0, 0.5);
  }
}
