@import "../../assets/styles/variables.scss";

.tk_campaign-request-container {
  padding: 15px 85px;
  &-button {
    width: 229px;
  }
  &-modal {
    z-index: 2100;
  }
}