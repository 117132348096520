@import "../../../../assets/styles/variables.scss";

.tk_dp-day,
.tk_dp-week {
  font-size: 12px;
}

.tk_dp-popper {
  min-width: 600px;
}

.react-datepicker__input-time-container {
  padding-left: 30px;
}

.tk_datepicker_header {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-next-month {
    background-color: $bg-grey !important;
    margin-left: 8px;
    margin-right: 15px;
    transform: rotate(180deg);
  }

  &-prev-month {
    background-color: $bg-grey !important;
    margin-left: 15px;
    margin-right: 8px;
  }

  &-year-month {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-month {
    margin-left: 10px;
    margin-right: 2px;
    color: $text-head;
  }

  &-year-picker {
    margin: 0 5px;
    display: flex;
    align-items: center;
    color: $text-head;

    &-arrow {
      margin-left: 4px;
      background-color: $text-head !important;
      transform: rotate(-90deg);
    }
  }

  .tk_dp-year-picker {
    .react-datepicker-year-header {
      font-size: 14px;
      font-weight: normal;
    }
    .react-datepicker__year-text {
      font-size: 14px;
      width: 3.5rem;
    }
    .react-datepicker__navigation-icon {
      top: 4px;
    }
  }
}
