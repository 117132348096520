@import '../../assets/styles/variables.scss';

.tk_section-header {
  font-size: 14px;
  letter-spacing: 0.46px;
  display: flex;
  justify-content: space-between;
  &-text {
    margin-top: 25px;
    font-size: 25px;
    color: black;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    > i {
      margin-right: 10px;
    }
  }
  &-showAll {
    color: $secondary;
    font-size: 12px;
    letter-spacing: -0.3px;
    cursor: pointer;
  }
}

.tk_section-header i {
  background-color: black!important
}