@import "../../assets/styles/variables.scss";

.tk_navbar {
  margin-top: 60px;
  width: 100vw;
  position: relative;
  min-height: 100px;
  position: fixed;
  z-index: 1999;
  justify-content: center;

  @media (max-width: 1400px) {
  }
  @media (max-width: 1199px) {
    min-height: 90px;
    padding: 20px 0px 20px 0px;
    justify-content: space-between;
  }

  &-topnavbar {
    z-index: 2000;
    width: 100vw;
    position: fixed;
    background-color: #db6314;
    height: 60px;
  }
  &-topsociallinks {
    float: left;
    > ul {
      margin: 0;
      padding: 0;
      float: left;
      list-style: none;
      line-height: 67px;
      > li {
        padding-left: 5px;
        padding-right: 5px;
        float: left;
        > a {
          background-color: white;
          padding-top: 10px;
          padding-right: 10px;
          padding-left: 10px;
          padding-right: 10px;

          > i {
            color: #db6314;
            width: auto;
            height: auto;
            margin-bottom: 2px;
          }
          > img {
            margin-top: -10px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .navbar-div {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: auto;
  }
  &-buttons {
    margin-top: 10px;
    float: right;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    @media (max-width: 1199px) {
    }
    .contact-div {
      text-align: center;
      > a {
        text-decoration: none !important;
        > Button {
          border-radius: 20px;
          width: 157px;
          height: 40px;
          letter-spacing: 1.38px;
          padding: 12px 0 11px;
          background: url("../../assets/images/contact-us.png") no-repeat center
            center;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
        }
      }
    }
  }
  .tk_brand {
    // position: absolute;
    // left: calc(((100% - 1000px) / 2) - 155px - 25px);
    margin: 0;
    margin-right: 50px;
    padding: 0;
    @media (max-width: 1400px) {
      position: initial;
      margin-right: 25px;
    }
    img {
      width: 200px;
    }
  }
  .navbar-toggler {
    color: $text-white;
    &:focus {
      box-shadow: none;
    }
  }
  &-links-wrapper {
    // width: 1000px;
    display: flex;
    // margin: 0 auto;
    @media (max-width: 1199px) {
      margin: 0;
      width: 100% !important;
    }
    @media (max-width: 1400px) {
      margin: 0;
      width: auto;
    }
  }
  .navbar-collapse {
    justify-content: center;
    > .navbar-nav {
      margin-right: auto;
      @media (max-width: 1199px) {
        margin-top: 20px;
      }
      > a {
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.08px;
        color: $text-white;
        font-size: 14px;
        margin-left: 25px;
        transition: $default-transition;
        &:hover,
        &:active,
        &.tk_nav-link-active {
          color: $secondary;
        }
        white-space: nowrap;
        @media (max-width: 1199px) {
          margin: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.tk_navbar-topnavbar ul li:nth-child(1) a:hover {
  background-color: #3b5998;
}

.tk_navbar-topnavbar ul li:nth-child(2) a:hover {
  background-color: #1da1f2;
}

.tk_navbar-topnavbar ul li:nth-child(3) a:hover {
  background-color: #e1306c;
}

.navbar-dropdown {
  margin-top: -1px;
  button {
    background-color: transparent;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.08px;
    color: #ffffff;
    font-size: 14px;
    margin-left: 24px;
    padding: 0;
    border: 0;
  }
  button:hover {
    color: #59c6f2;
  }
}

@media (max-width: 1199.98px) {
  .tk_navbar .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
