@import "../../assets/styles/variables.scss";

.tk_login-container {
    text-align: center;
    // margin-left: 100px;
    // min-width: 220px;
    > button {
      border-radius: 20px;
      width: 180px;
      height: 40px;
      text-transform: uppercase;
      // letter-spacing: 1.38px;
      margin-left: 0.50rem;
      // background: url('../../assets/images/contact-us.png') no-repeat center center;
      background-color: $bg-light-blue;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
}

.tk_login-signup-modal {
  z-index: 2100;
}
